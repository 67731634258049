import React, { useState, useEffect } from 'react'
import AccordionItem from './AccordionItem'
import * as Styled from './Accordion.styles'

const Accordion = ({ items }) => {
  const [activeIndex, setIndex] = useState(null)
  const [status, setStatus] = useState(Array(items.length).fill(false))

  useEffect(() => {
    const currentStatus = [...status]

    items.forEach((item, index) => {
      if (item.active !== undefined) {
        currentStatus[index] = true
        setIndex(index)
      }
    })

    setStatus(currentStatus)
  }, [])

  const handlaAccordionItemClick = index => {
    const currentStatus = [...status]
    currentStatus[index] = !status[index]

    if (activeIndex !== index) {
      currentStatus[activeIndex] = false

      setIndex(index)
    } else {
      setIndex(null)
    }

    setStatus(currentStatus)
  }

  return (
    <Styled.Accordion>
      {items.map((item, index) => (
        <AccordionItem
          key={item.q}
          data={item}
          onClick={() => handlaAccordionItemClick(index)}
          opened={status[index]}
        />
      ))}
    </Styled.Accordion>
  )
}

export default Accordion
