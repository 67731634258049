import styled, { css } from 'styled-components'

const AccordionItem = styled.li`
  cursor: pointer;
  flex: 0 0 100%;
  max-width: 100%;
  outline: none;
  border-top: 2px solid ${props => props.theme.colors.light};

  &:last-of-type {
    border-bottom: 2px solid ${props => props.theme.colors.light};
  }
`

const AccordionHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  line-height: 1.1;
  padding: 16px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 24px 0;
  }

  p {
    margin: 0;
    max-width: calc(100% - 66px);

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 16px;
    }
  }

  span {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.dark};
    font-size: 50px;
    font-weight: 300;
    line-height: 50px;
    text-align: center;
    transition: 0.2s transform;
    user-select: none;
  }

  ${props =>
    props.opened &&
    css`
      span {
        transform: rotate(45deg);
      }
    `}
`

const AccordionContent = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.2s height;
`

const AccordionInner = styled.div`
  padding: 16px 0;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 24px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 3px;
    background-color: ${props => props.theme.colors.addon};

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      width: 98px;
    }
  }

  sup {
    font-size: 0.6em;
  }

  ol {
    counter-reset: listCounter;

    > li {
      position: relative;
      padding: 0 0 8px 24px;
      color: ${props => props.theme.colors.dark};
      counter-increment: listCounter;

      &:before {
        content: counter(listCounter) '.';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`

export { AccordionItem, AccordionHeader, AccordionContent, AccordionInner }
