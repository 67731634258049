import React, { useState, useEffect, useRef } from 'react'
import * as Styled from './AccordionItem.styles'

const AccordionItem = ({ data, onClick, opened }) => {
  const [height, setHeight] = useState(0)
  const refContent = useRef(null)
  const refInner = useRef(null)

  useEffect(() => {
    if (opened) {
      let temp

      if (!height) {
        temp = refInner.current.clientHeight
        setHeight(temp)
      }

      refContent.current.style.height = `${temp || height}px`
    } else {
      refContent.current.style.height = ''
    }
  })

  return (
    <Styled.AccordionItem opened={opened} onClick={() => onClick()}>
      <Styled.AccordionHeader opened={opened}>
        <p className="lead">{data.q}</p>
        <span aria-hidden="true">+</span>
      </Styled.AccordionHeader>
      <Styled.AccordionContent ref={refContent}>
        <Styled.AccordionInner ref={refInner}>{data.a}</Styled.AccordionInner>
      </Styled.AccordionContent>
    </Styled.AccordionItem>
  )
}

export default AccordionItem
