import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionHeader from '../components/SectionHeader'
import SectionFaq from '../components/SectionFaq'

export default function Home() {
  const { headerBgMobile, headerBg } = useStaticQuery(
    graphql`
      query {
        headerBgMobile: file(
          relativePath: { eq: "qanda/qanda-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        headerBg: file(relativePath: { eq: "qanda/qanda-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="Polityka prywatności"
      metaDescription=""
      slug="/polityka-prywatnosci"
    >
      <SectionHeader
        smallTitle="Nordic Logistics"
        title={
          <>
            Polityka <br />
            prywatności
          </>
        }
        titleBorder="white"
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        bgImage={headerImages}
      />
      <SectionFaq
        id="czytaj-wiecej"
        items={[
          {
            q: 'Klauzula informacyjna o przetwarzaniu danych osobowych',
            a: (
              <div>
                <p>
                  Informujemy, że jesteśmy gotowi, aby chronić Twoje dane na
                  najwyższym poziomie, uwzględniając przepisy prawa.
                  Jednocześnie chcemy poinformować Ciebie o Twoich prawach,
                  które będą Tobie przysługiwały w związku z nowymi przepisami.
                </p>
                <p>
                  Zgodnie z art. 13 ust. 1 i 2&nbsp;Rozporządzenia Parlamentu
                  Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27
                  kwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych
                  w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w
                  sprawie swobodnego przepływu takich danych oraz uchylenia
                  dyrektywy 95/46/WE (ogólne rozporządzenie o&nbsp;ochronie
                  danych, zwane: „RODO”), informujemy, iż:
                </p>
                <p>
                  Podstawą prawną przetwarzania Twoich danych osobowych jest:
                </p>
                <ol>
                  <li>
                    1. Administratorem danych osobowych jest NORDIC LOGISTICS
                    POLSKA spółka z o.o., wpisana do rejestru przedsiębiorców
                    prowadzonego przez Sąd Rejonowy w Warszawie, XII Wydział
                    Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
                    0000604801, NIP: 6832088830, REGON: 363846371, adres miejsca
                    wykonywania działalności i adres do doręczeń: ul. Kasztanowa
                    57, 85-605 Bydgoszcz, adres poczty elektronicznej:
                    biuro@nordiclogistics.pl, tel. + 48 531 836 047.
                  </li>
                  <li>
                    Podstawą prawną przetwarzania Twoich danych osobowych jest:
                    <ul>
                      <li>
                        o zgodnie z art. 6 ust. 1 lit. a) RODO – zgoda na
                        przetwarzanie danych osobowych, w celu nawiązania
                        ewentualnej współpracy bądź kontynuowania istniejących
                        stosunków handlowych, przesyłania informacji handlowej
                        lub otrzymywania informacji marketingowych, w tym
                        poprzez zautomatyzowane przetwarzanie, w tym
                        profilowanie,
                      </li>
                      <li>
                        o zgodnie z art. 6 ust. 1 lit. b) RODO – realizacja
                        umowy (zwanej: „Umową”), dla której przetwarzanie danych
                        jest niezbędne, a dane osobowe przetwarzane są wyłącznie
                        dla celów związanych z realizacją Umowy oraz do podjęcia
                        niezbędnych działań przed zawarciem Umowy, a także do
                        ustalenia dochodzenia lub obrony roszczeń,
                      </li>
                      <li>
                        zgodnie z art. 6 ust. 1 lit. c) RODO – do wypełnienia
                        obowiązku prawnego ciążącego na Administratorze,
                      </li>
                      <li>
                        godnie z art. 6 ust. 1 lit. f) RODO –&nbsp;do celów
                        wynikających z prawnie uzasadnionych interesów
                        realizowanych przez Administratora, tj. w celu działań
                        marketingowych oraz dochodzenia roszczeń.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Dane osobowe będą przetwarzane dla potrzeb marketingu
                    bezpośredniego produktów i usług do czasu zgłoszenia przez
                    Ciebie sprzeciwu względem ich przetwarzania w tym celu lub
                    ustalenia przez Administratora danych osobowych, że się
                    zdezaktualizowały.
                  </li>
                  <li>
                    4. Twoje dane osobowe będą i są przetwarzane w ramach
                    prawnie usprawiedliwionego interesu Administratora danych
                    osobowych w celu przesyłania informacji handlowej w formie
                    marketingu bezpośredniego w celu ewentualnego zawarcia
                    Umowy, oraz do podjęcia niezbędnych działań przed zawarciem
                    Umowy, a następnie w zakresie niezbędnym do wykonywania
                    Umowy oraz dla realizacji przepisów prawa. W ramach
                    realizacji tych celów dane będą przetwarzane w
                    szczególności: do kontaktów w związku z marketingiem
                    bezpośrednim, do realizacji zamówień, wykonywania umów oraz
                    umożliwienia i utrzymywania kontaktu biznesowego, składania
                    reklamacji i innych niezbędnych czynności takich jak
                    prowadzenie rachunkowości i rozliczenia podatkowe.
                  </li>
                  <li>
                    Podanie danych osobowych jest&nbsp;dobrowolne, jednakże ich
                    niepodanie spowoduje, że zawarcie i realizacja Umowy lub
                    realizacja działań marketingowych nie będą możliwe.
                  </li>
                  <li>
                    Dane będą przechowywane nie dłużej niż jest to konieczne,
                    tj.&nbsp;&nbsp;do dnia rozwiązania Umowy, nie krócej jednak
                    niż okres przedawnienia ewentualnych roszczeń związanych z
                    zawarciem i wykonaniem Umowy albo do czasu cofnięcia zgody,
                    w przypadku, gdy była wymagana, albo wniesienia sprzeciwu.
                  </li>
                  <li>
                    Twoje dane mogą być udostępniane odbiorcom, jeżeli będzie to
                    wymagane przepisami prawa, a także w przypadku, gdy wymaga
                    tego usługa, którą zamówiłeś. Odbiorcami danych osobowych
                    są: podmioty świadczące usługi księgowe i podatkowe,
                    prowadzące działalność kurierską i pocztową, pomoc prawną,
                    usługi płatnicze, usługi z dziedziny ubezpieczeń, usługi
                    informatyczne.
                  </li>
                  <li>
                    Dane osobowe nie będą przekazywane poza teren państw
                    trzecich (Europejskiego Obszaru Gospodarczego) ani
                    organizacji międzynarodowych.
                  </li>
                  <li>
                    Posiadasz prawo dostępu do treści swoich danych oraz
                    otrzymania ich kopii, oraz prawo ich sprostowania,
                    usunięcia, ograniczenia przetwarzania, prawo do przenoszenia
                    danych, prawo do cofnięcia zgody na ich przetwarzanie
                    w&nbsp;dowolnym momencie bez wpływu na zgodność z prawem
                    przetwarzania, którego dokonano na podstawie zgody wyrażonej
                    przed jej cofnięciem.
                  </li>
                  <li>
                    Masz prawo wniesienia sprzeciwu wobec przetwarzania danych.
                  </li>
                  <li>
                    Masz prawo otrzymać w ustrukturyzowanym, powszechnie
                    używanym formacie nadającym się do odczytu maszynowego dane
                    osobowe Ciebie dotyczące, które dostarczyłaś/dostarczyłeś na
                    podstawie zgody lub Umowy, w tym możesz zlecić przesłanie
                    tych danych bezpośrednio innemu podmiotowi.<sup>1</sup>
                  </li>
                  <li>
                    Masz prawo wniesienia skargi do Prezesa Urzędu Ochrony
                    Danych Osobowych lub innego właściwego organu nadzorczego,
                    gdy uznasz, iż przetwarzanie danych osobowych Ciebie
                    dotyczących narusza przepisy RODO.
                  </li>
                  <li>
                    W związku ze świadczeniem usług możemy dokonywać
                    profilowania<sup>2</sup>, wykorzystując do tego podane przez
                    Ciebie dane osobowe. Profilowanie to polega przede wszystkim
                    na automatycznej ocenie, jakimi usługami możesz być
                    zainteresowany, na podstawie Twoich dotychczasowych działań
                    i na wysyłaniu bądź wyświetlaniu profilowanych w ten sposób
                    ofert lub reklam usług.
                  </li>
                  <li>
                    W celu realizacji swoich praw, prosimy o kontakt pod
                    wskazany w pkt 1 powyżej adres e-mail.
                  </li>
                </ol>
                <p>
                  <sup>1</sup> jeżeli przetwarzanie opiera się na podstawie
                  prawnej zgody lub umowy
                </p>
                <p>
                  <sup>2</sup> Profilowanie oznacza dowolną formę
                  zautomatyzowanego przetwarzania danych osobowych, które polega
                  na wykorzystaniu danych osobowych do oceny niektórych
                  czynników osobowych osoby fizycznej,&nbsp;w szczególności do
                  analizy lub prognozy aspektów dotyczących pracy tej osoby
                  fizycznej, jej sytuacji ekonomicznej, zdrowia, osobistych
                  preferencji, zainteresowań, wiarygodności, zachowania,
                  lokalizacji lub przemieszczania się.
                </p>
                <p>
                  NORDIC LOGISTICS POLSKA&nbsp;Spółka z ograniczoną
                  odpowiedzialnością
                </p>
                <p>Administrator Danych Osobowych</p>
              </div>
            ),
          },
          {
            q: 'Klauzula informacyjna o przetwarzaniu danych w związku z rekrutacją',
            a: (
              <div>
                <p>
                  Informujemy, że jesteśmy gotowi, aby chronić Twoje dane na
                  najwyższym poziomie, uwzględniając przepisy prawa.
                  Jednocześnie chcemy poinformować Ciebie o Twoich prawach,
                  które będą Tobie przysługiwały w związku z nowymi przepisami:
                </p>
                <ol>
                  <li>
                    <strong>
                      Administratorem danych osobowych jest&nbsp;NORDIC
                      LOGISTICS POLSKA spółka z o.o.
                    </strong>
                    ,&nbsp;wpisana do rejestru przedsiębiorców prowadzonego
                    przez Sąd Rejonowy w Warszawie, XII Wydział Gospodarczy
                    Krajowego Rejestru Sądowego pod numerem KRS: 0000604801,
                    NIP: 6832088830, REGON: 363846371, adres miejsca wykonywania
                    działalności i adres do doręczeń: ul. Kasztanowa 57, 85-605
                    Bydgoszcz, adres poczty elektronicznej:
                    biuro@nordiclogistics.pl, tel. + 48 531 836 047.
                  </li>
                  <li>
                    Dane osobowe będą przetwarzane w celu udziału w procesie
                    rekrutacyjnym oraz – w przypadku wyrażenia na to zgody – w
                    dalszych procesach rekrutacyjnych.
                  </li>
                  <li>
                    Podanie danych osobowych jest&nbsp;
                    <strong>dobrowolne</strong>, ale konieczne dla wzięcia
                    udziału w procesie rekrutacyjnym, a w przypadku wyrażenia na
                    to zgody – również w dalszych procesach rekrutacyjnych.
                  </li>
                  <li>
                    Przetwarzanie podanych danych osobowych w toku obecnej
                    rekrutacji odbywa się w oparciu o dobrowolnie wyrażoną zgodę
                    (art. 6 ust. 1 lit. a) RODO)*. Przetwarzanie danych
                    osobowych w dalszych procesach rekrutacyjnych odbywa się na
                    podstawie zgody (art. 6 ust. 1 lit. a) RODO).
                  </li>
                  <li>
                    Dane będą mogły być również przetwarzane niezależnie od
                    wyrażonej zgody, w związku z dochodzeniem/obroną roszczeń
                    (art. 6 ust. 1 lit. f) RODO).
                  </li>
                  <li>
                    Masz prawo do żądania od administratora dostępu do moich
                    danych osobowych, otrzymania kopii, ich sprostowania,
                    usunięcia lub ograniczenia przetwarzania, a także prawo do
                    przenoszenia danych oraz prawo do złożenia oświadczenia o
                    cofnięciu każdej wyrażonej zgody w każdym czasie. Cofnięcie
                    zgody nie ma wpływu na zgodność z prawem przetwarzania,
                    którego dokonano na podstawie zgody przed jej cofnięciem.
                  </li>
                  <li>
                    Masz prawo do wniesienia sprzeciwu wobec przetwarzania moich
                    danych osobowych.
                  </li>
                  <li>
                    Dane osobowe będą przetwarzane przez okres nie dłuższy niż
                    przez czas trwania procesu rekrutacji, nie dłużej jednak niż
                    dwa lata od dnia wyrażenia zgody.
                  </li>
                  <li>
                    Masz prawo otrzymać w ustrukturyzowanym, powszechnie
                    używanym formacie nadającym się do odczytu maszynowego dane
                    osobowe mnie dotyczące, które dostarczyłam/dostarczyłem na
                    podstawie zgody, w tym mogę zlecić przesłanie tych danych
                    bezpośrednio innemu podmiotowi.
                  </li>
                  <li>
                    Dane osobowe nie będą przekazywane innym odbiorcom, ani do
                    państw trzecich (poza Europejski Obszar Gospodarczy) ani
                    organizacji międzynarodowych.
                  </li>
                  <li>Dane nie będą przetwarzane w sposób zautomatyzowany.</li>
                  <li>
                    Masz prawo wniesienia skargi do Prezesa Urzędu Ochrony
                    Danych Osobowych lub innego właściwego organu nadzorczego,
                    gdy uznam, iż przetwarzanie danych osobowych mnie
                    dotyczących narusza przepisy RODO.
                  </li>
                  <li>
                    Brak zgody uniemożliwi rozpatrzenie przesłanej oferty.
                  </li>
                </ol>
                <p>
                  * RODO oznacza rozporządzenie Parlamentu Europejskiego i Rady
                  (UE) 2016/679 z dnia 27.04.2016 r. w sprawie ochrony osób
                  fizycznych w związku z przetwarzaniem danych osobowych i w
                  sprawie swobodnego przepływu takich danych oraz uchylenia
                  dyrektywy 95/46/WE
                  <br />
                  <em>
                    NORDIC LOGISTICS POLSKA&nbsp;Spółka z ograniczoną
                    odpowiedzialnością
                  </em>
                  <br />
                  Administrator Danych Osobowych
                </p>
              </div>
            ),
          },
          {
            q: 'Pliki Cookies',
            a: (
              <div>
                <ol>
                  <li>
                    Serwis nie zbiera w sposób automatyczny żadnych informacji,
                    z wyjątkiem informacji zawartych w plikach cookies.
                  </li>
                  <li>
                    Pliki cookies (tzw. „ciasteczka”) stanowią dane
                    informatyczne, w szczególności pliki tekstowe, które
                    przechowywane są w urządzeniu końcowym Użytkownika Serwisu i
                    przeznaczone są do korzystania ze stron internetowych
                    Serwisu. Cookies zazwyczaj zawierają nazwę strony
                    internetowej, z której pochodzą, czas przechowywania ich na
                    urządzeniu końcowym oraz unikalny numer.
                  </li>
                  <li>
                    3. Podmiotem zamieszczającym na urządzeniu końcowym
                    Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
                    dostęp jest operator Serwisu Nordic Logistics Polska Sp. z
                    o.o. z siedzibą w Bydgoszczy pod adresem ul. Kasztanowa 57,
                    85-605 Bydgoszcz, wpisanym do Rejestru Przedsiębiorców
                    prowadzonego przez Sąd Rejonowy dla M. St. Warszawy w
                    Warszawie, XII Wydział Gospodarczy Krajowego Rejestru
                    Sądowego pod nr KRS: 0000604801, NIP: 6832088830, REGON:
                    363846371, kapitał zakładowy 500 000,00 zł.
                  </li>
                  <li>
                    Pliki cookies wykorzystywane są w celu:
                    <ul>
                      <li>
                        dostosowania zawartości stron internetowych Serwisu do
                        preferencji Użytkownika oraz optymalizacji korzystania
                        ze stron internetowych; w szczególności pliki te
                        pozwalają rozpoznać urządzenie Użytkownika Serwisu i
                        odpowiednio wyświetlić stronę internetową, dostosowaną
                        do jego indywidualnych potrzeb;
                      </li>
                      <li>
                        umożliwienia prawidłowego wyświetlania strony
                        internetowej i korzystania z wszystkich jej
                        funkcjonalności;
                      </li>
                      <li>
                        tworzenia statystyk, które pomagają zrozumieć, w jaki
                        sposób Użytkownicy Serwisu korzystają ze strony
                        internetowej, co umożliwia ulepszanie ich struktury i
                        zawartości.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Pliki cookies nie powodują zmian konfiguracyjnych w
                    urządzeniu lub oprogramowaniu zainstalowanym na urządzeniu
                    Użytkownika Serwisu.
                  </li>
                  <li>
                    W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
                    cookies: „sesyjne”&nbsp;(<em>session cookies</em>) oraz
                    „stałe” (<em>persistent cookies</em>). Cookies „sesyjne” są
                    plikami tymczasowymi, które przechowywane są w urządzeniu
                    końcowym Użytkownika do czasu wylogowania, opuszczenia
                    strony internetowej lub wyłączenia oprogramowania
                    (przeglądarki internetowej). „Stałe” pliki cookies
                    przechowywane są w urządzeniu końcowym Użytkownika przez
                    czas określony w parametrach plików cookies lub do czasu ich
                    usunięcia przez Użytkownika. Użytkownik ma jednak zawsze
                    możliwość usunięcia tych plików w ustawieniach swojej
                    przeglądarki internetowej.
                  </li>
                  <li>
                    W ramach Serwisu stosowane są następujące rodzaje plików
                    cookies:
                    <ul>
                      <li>
                        „niezbędne” pliki cookies, umożliwiające korzystanie z
                        usług dostępnych w ramach Serwisu, np. uwierzytelniające
                        pliki cookies wykorzystywane do usług wymagających
                        uwierzytelniania w ramach Serwisu;
                      </li>
                      <li>
                        pliki cookies służące do zapewnienia bezpieczeństwa, np.
                        wykorzystywane do wykrywania nadużyć w zakresie
                        uwierzytelniania w ramach Serwisu;
                      </li>
                      <li>
                        „wydajnościowe” pliki cookies, umożliwiające zbieranie
                        informacji o sposobie korzystania ze stron internetowych
                        Serwisu;
                      </li>
                      <li>
                        „funkcjonalne” pliki cookies, umożliwiające
                        „zapamiętanie” wybranych przez Użytkownika ustawień i
                        personalizację interfejsu Użytkownika (np. w zakresie
                        wybranego języka lub regionu, z którego pochodzi
                        Użytkownik, rozmiaru czcionki, wyglądu strony
                        internetowej, wyrażonych zgód itp.).;
                      </li>
                      <li>
                        „reklamowe” pliki cookies, umożliwiające dostarczanie
                        Użytkownikom treści reklamowych bardziej dostosowanych
                        do ich zainteresowań.
                      </li>
                    </ul>
                  </li>
                  <li>
                    W wielu przypadkach oprogramowanie służące do przeglądania
                    stron internetowych (przeglądarka internetowa) domyślnie
                    dopuszcza przechowywanie plików cookies w urządzeniu
                    końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w
                    każdym czasie zmiany ustawień dotyczących plików cookies.
                    Ustawienia te mogą zostać zmienione w szczególności w taki
                    sposób, aby blokować automatyczną obsługę plików cookies w
                    ustawieniach przeglądarki internetowej bądź informować o ich
                    każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu.
                    Szczegółowe informacje o możliwości i sposobach obsługi
                    plików cookies dostępne są w ustawieniach oprogramowania
                    (przeglądarki internetowej). Pod poniższymi linkami
                    zamieszczone są informacje o sposobach usunięcia cookies w
                    najpopularniejszych przeglądarkach internetowych:
                    <ul>
                      <li>
                        FIREFOX: support.mozilla.org/pl/kb/usuwanie-ciasteczek;
                      </li>
                      <li>OPERA: opera.com/pl/latest/security-and-privacy/;</li>
                      <li>
                        INTERNET EXPLORER:
                        windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11;
                      </li>
                      <li>
                        CHROME: support.google.com/chrome/answer/95647?hl=pl.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Operator Serwisu informuje, że ograniczenia stosowania
                    plików cookies mogą wpłynąć na niektóre funkcjonalności
                    dostępne na stronach internetowych Serwisu.
                  </li>
                  <li>
                    Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                    Serwisu wykorzystywane mogą być również przez
                    współpracujących z operatorem Serwisu reklamodawców oraz
                    partnerów. Operator serwisu wykorzystuje cookies podmiotów
                    zewnętrznych w celu popularyzacji Serwisu oraz w celu
                    analizy statystyk Serwisu, takie jak:
                    <ul>
                      <li>
                        cookies google-analytics.com – służące do prowadzenia
                        statystyk; dokładny sposób działania Google Analytics
                        dostępny jest pod adresem:
                        <a href="https://marketingplatform.google.com/about/analytics/terms/pl/">
                          https://marketingplatform.google.com/about/analytics/terms/pl/
                        </a>
                        ,{' '}
                        <a href="https://policies.google.com/privacy">
                          https://policies.google.com/privacy
                        </a>
                      </li>
                      <li>
                        pliki cookies narzędzi społecznościowych, takich jak
                        Facebook, Instagram, czy LinkedIn, w związku z faktem,
                        iż na stronie internetowej Serwisu znajdują się wtyczki
                        do wskazanych portali internetowych. Wtyczki te mogą
                        gromadzić pewne informacje na temat Użytkownika, które
                        nie są przekazywane Operatorowi Serwisu. Jeśli
                        Użytkownik nie wyraża zgody, aby serwisy społecznościowe
                        pozyskiwały informacje dotyczące jego wizyt na stronie,
                        powinien przed wejściem na stronę internetową Serwisu
                        wylogować się z portali społecznościowych. Informacje na
                        temat zbieranych danych przez Facebook, Instagram i
                        LinkedIn dostępne są na stronach:{' '}
                        <a href="https://www.facebook.com/privacy/explanation">
                          https://www.facebook.com/privacy/explanation
                        </a>
                        ,{' '}
                        <a href="https://help.instagram.com/519522125107875?helpref=page_content">
                          https://help.instagram.com/519522125107875?helpref=page_content
                        </a>{' '}
                        oraz{' '}
                        <a href="https://www.linkedin.com/legal/privacy-policy">
                          https://www.linkedin.com/legal/privacy-policy
                        </a>
                        .
                      </li>
                    </ul>
                  </li>
                  <li>
                    Więcej informacji na temat plików cookies dostępnych jest w
                    sekcji „Pomoc” w menu przeglądarki internetowej.
                  </li>
                </ol>
                <br />
                <p>
                  Powyższy wzór polityki cookies chroniony jest prawem
                  autorskim, które przysługują IAB Polska. Źródło:
                  http://wszystkoociasteczkach.pl/
                </p>
              </div>
            ),
          },
          {
            q: 'Umowy',
            a: (
              <div>
                <p>
                  <a href="dokumenty/Umowy-powierzenia-przetwarzania-danych-osobowych-zlecenie-transportowe.docx">
                    Umowy powierzenia przetwarzania danych osobowych/ zlecenie
                    transportowe
                  </a>
                </p>
                <p>
                  <a href="dokumenty/Umowy-powierzenia-przetwarzania-danych-osobowych-zlecenie-spedycyjne.docx">
                    Umowy powierzenia przetwarzania danych osobowych/ zlecenie
                    spedycyjne
                  </a>
                </p>
              </div>
            ),
          },
        ]}
      />
    </Layout>
  )
}
