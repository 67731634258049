import React from 'react'
import * as Styled from './SectionFaq.styles'
import { Column, Row } from '../../layout/Grid'
import Accordion from '../Accordion'

const SectionFaq = ({ id, title, items }) => {
  return (
    <Styled.Section id={id}>
      <Row>
        {title && (
          <Column xs={12} lg={4}>
            <h2 className="h1">{title}</h2>
          </Column>
        )}
        <Column xs={12} lg={title ? 8 : 12}>
          <Accordion items={items} />
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionFaq
