import styled from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 24px 0;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 80px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 120px 0;
  }

  h2 {
    margin-bottom: 24px;
  }
`

export { Section }
